/** VERSION: 2.1.8-2020.02.21-07.02.29 Please don't modify the minified content. This content will be updated regularly **/
/* breakpoint on mobile */
/* search mobile height */
/* boostcommerce font */
@font-face {
  font-family: bc-sf-filter-ico;
  src: url("\/\/www.topdealsonline.shop\/cdn\/shop\/t\/10\/assets\/bc-sf-filter-ico.eot?1fx0e3\u0026v=71133625122506434571616158366");
  src: url("\/\/www.topdealsonline.shop\/cdn\/shop\/t\/10\/assets\/bc-sf-filter-ico.eot?1fx0e3%23iefix\u0026v=71133625122506434571616158366") format("embedded-opentype"), url("\/\/www.topdealsonline.shop\/cdn\/shop\/t\/10\/assets\/bc-sf-filter-ico.ttf?1fx0e3\u0026v=149092035153215201481616158367") format("truetype"), url("\/\/www.topdealsonline.shop\/cdn\/shop\/t\/10\/assets\/bc-sf-filter-ico.woff?1fx0e3\u0026v=152220026089263463831616158368") format("woff"), url("\/\/www.topdealsonline.shop\/cdn\/shop\/t\/10\/assets\/bc-sf-filter-ico.svg?1fx0e3%23icomoon\u0026v=117370087076797144871616158367") format("svg");
  font-weight: 400;
  font-style: normal; }

.bc-sf-filter-icon-star:before, .noUi-tooltip, .noUi-value {
  text-align: center; }

.bc-sf-search-box:focus, .bc-sf-search-btn-clear-suggestion:focus, .jspScrollable:focus, .jspVerticalBar .jspArrow:focus {
  outline: 0; }

.jspPane {
  position: absolute; }

.jspTrack {
  position: relative;
  background: #dde; }

.jspContainer {
  overflow: hidden;
  position: relative; }

.jspHorizontalBar, .jspPane, .jspVerticalBar {
  position: absolute; }

.jspVerticalBar {
  top: 0;
  right: 0;
  height: 100%; }

.jspHorizontalBar {
  bottom: 0;
  left: 0;
  width: 100%; }

.jspDrag, .jspTrack, .noUi-base, .noUi-target {
  position: relative; }

.jspCap {
  display: none; }

.jspHorizontalBar .jspCap {
  float: left; }

.jspDrag {
  top: 0;
  left: 0;
  cursor: pointer; }

.jspHorizontalBar .jspDrag, .jspHorizontalBar .jspTrack {
  float: left;
  height: 100%; }

.jspArrow {
  background: #50506d;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
  padding: 0;
  margin: 0; }

.jspArrow.jspDisabled {
  cursor: default;
  background: #80808d; }

.jspVerticalBar .jspArrow {
  height: 16px; }

.jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%; }

.jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%; }

* html .jspCorner {
  margin: 0 -3px 0 0; }

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  direction: ltr;
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0,0 3px 6px -5px #BBB; }

.noUi-base {
  width: 100%;
  height: 100%;
  z-index: 1; }

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  -webkit-transition: top .3s,right .3s,bottom .3s,left .3s;
  transition: top .3s,right .3s,bottom .3s,left .3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px; }

.noUi-connect {
  background: #3FB8AF;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms; }

.noUi-draggable {
  cursor: w-resize; }

.noUi-vertical .noUi-draggable {
  cursor: n-resize; }

.noUi-handle {
  position: relative;
  z-index: 1;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #EBEBEB,0 3px 6px -3px #BBB; }

.noUi-marker, .noUi-pips, .noUi-tooltip, .noUi-value {
  position: absolute; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,inset 0 1px 7px #DDD,0 3px 6px -3px #BBB; }

.noUi-handle:after, .noUi-handle:before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed; }

.noUi-pips, .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  color: #999; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

.noUi-marker {
  background: #CCC; }

.noUi-marker-large, .noUi-marker-sub {
  background: #AAA; }

.noUi-pips-horizontal {
  top: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%, 50%, 0);
  transform: translate3d(-50%, 50%, 0); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  padding-left: 25px; }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.jspContainer {
  width: 100% !important; }

.jspVerticalBar {
  width: 5px;
  background: #ececec;
  cursor: pointer; }

.jspHorizontalBar {
  height: 5px;
  background: #ececec; }

.jspDrag {
  background: #a7a7a7; }

div.checker, div.selector.focus, div.selector.hover {
  opacity: 1; }

.bc-sf-filter-clearfix {
  clear: both; }

.bc-sf-filter-option-hidden {
  display: none !important; }

.bc-sf-filter-sprite {
  background: url(bc-sf-filter-sprite.png?v=6) no-repeat; }

.bc-sf-filter-block-title h3 {
  display: inline-block; }

.bc-sf-filter-block-title h3 span {
  cursor: pointer; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li a {
  display: block;
  margin-bottom: 7px;
  font-weight: 400;
  text-decoration: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li a.selected {
  font-weight: 700; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li a[data-count='0'] {
  opacity: 0.3 !important; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li input[type=checkbox] {
  display: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li input[type=checkbox] + a {
  position: relative;
  padding-left: 23px;
  line-height: 22px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li input[type=checkbox] + a > span:first-child {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  width: 14px;
  height: 14px;
  position: absolute;
  background: 0 0;
  left: 1px;
  top: 3px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li input[type=checkbox] + a.selected {
  font-weight: 400; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li input[type=checkbox] + a.selected > span:first-child:after {
  content: '';
  width: 9px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
  border: 2px solid #000;
  border-top: none;
  border-right: none;
  background: 0 0;
  transform: rotate(-50deg);
  box-sizing: border-box; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li a {
  position: relative;
  padding-left: 23px;
  line-height: 22px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li a:hover {
  text-decoration: none;
  opacity: 1; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li a > span:first-child {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  width: 14px;
  height: 14px;
  position: absolute;
  background: 0 0;
  left: 1px;
  top: 3px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li a.selected > span:first-child:before {
  content: '';
  width: 9px;
  height: 6px;
  position: absolute;
  top: 2px;
  left: 3px;
  border: 2px solid #000;
  border-top: none;
  border-right: none;
  background: 0 0;
  -webkit-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li input[type=checkbox] {
  cursor: pointer; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-range-amount {
  margin-bottom: 20px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-amount {
  margin-left: 3px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content label {
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
  float: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box:after {
  clear: both;
  content: '';
  display: block; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li {
  float: left;
  display: inline-block;
  margin-bottom: 5px;
  border: 1px solid #ccc; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a {
  margin-bottom: 0;
  text-align: center; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a.selected {
  background: #000;
  color: white !important; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a:hover {
  text-decoration: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-block-content-inner ~ a {
  margin-right: 10px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a[data-value=White] span.bc-sf-filter-option-swatch-image {
  border: 1px solid #cbcbcb !important; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a.selected .bc-sf-filter-option-swatch-image, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a:hover .bc-sf-filter-option-swatch-image, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a[data-value=White].selected span.bc-sf-filter-option-swatch-image {
  border: 2px solid black !important; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a .bc-sf-filter-option-swatch-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  padding: 2px;
  width: 30px;
  height: 30px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-size: cover;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a.selected, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch li a:hover {
  font-weight: 400; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a span, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-list li a span {
  border-radius: 50%; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.square-grid li {
  display: inline-block; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.square-grid li a span:not(.bc-sf-filter-option-swatch-image) {
  display: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.square-list li {
  display: block; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li {
  display: inline-block; }

#bc-sf-filter-bottom-pagination, #bc-sf-filter-load-more, #bc-sf-filter-top-pagination, #bc-sf-filter-tree-mobile, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a span:not(.bc-sf-filter-option-swatch-image), [data-bc-sort=best-selling] {
  display: none; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-list li {
  display: block; }

#bc-sf-filter-scroll-to-top span:before, .bc-sf-filter-icon-star:before {
  display: inline-block;
  font-family: bc-sf-filter-ico;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bc-sf-filter-icon-star {
  font-size: 0; }

.bc-sf-filter-icon-star:before {
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  font-variant: normal;
  line-height: 1em;
  margin-left: .2em;
  color: inherit;
  content: "\e906";
  font-size: 16px; }

.bc-sf-filter-icon-star.bc-sf-filter-icon-star-active:before {
  content: "\e905"; }

.bc-sf-filter-option-block[data-show-more-type*=scrollbar] .bc-sf-filter-block-content {
  max-height: 220px;
  overflow: hidden; }

.bc-sf-filter-option-block .bc-sf-filter-block-content.no-scrollbar {
  max-height: none !important; }

.bc-sf-filter-option-block .jspScrollable {
  position: relative; }

.bc-sf-filter-option-block .jspScrollable:after, .bc-sf-filter-option-block .jspScrollable:before {
  content: '';
  position: absolute;
  pointer-events: none;
  opacity: 0;
  visibility: hidden; }

.bc-sf-filter-option-block .jspScrollable:before {
  bottom: 0;
  left: 0;
  right: 5px;
  height: 35px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), white);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0), white);
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease; }

.bc-sf-filter-option-block .jspScrollable:after {
  background: url(bc-sf-filter-loading.gif) center center no-repeat;
  background-size: cover;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; }

.bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading:after, .bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading:before {
  opacity: 1;
  visibility: visible; }

.bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading.bc-icon-center:after {
  bottom: 50%;
  transform: translate(-50%, 50%); }

.bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading.bc-icon-center:before {
  height: auto;
  top: 0;
  background: rgba(255, 255, 255, 0.8); }

#bc-sf-filter-error, #bc-sf-filter-loading {
  position: fixed;
  top: 50% !important;
  bottom: auto;
  left: 50%; }

#bc-sf-filter-load-more-loading-icon, #bc-sf-filter-loading {
  background: url(bc-sf-filter-loading.gif) center 14px no-repeat white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 56px;
  height: 56px;
  padding: 0;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  z-index: 9999999999;
  text-align: center;
  overflow: hidden;
  text-indent: -999em; }

.bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading.bc-icon-center.bc-scrollmore-loaded:after, .bc-sf-filter-option-block .jspScrollable.bc-scrollmore-loading.bc-icon-center.bc-scrollmore-loaded:before {
  opacity: 0;
  visibility: hidden; }

#bc-sf-filter-tree-mobile button {
  width: 100%;
  background: #000;
  color: #fff;
  padding: 10px 0;
  margin: 20px 0;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  display: block; }

#bc-sf-filter-loading {
  right: auto;
  margin-left: -28px;
  margin-top: -28px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

#bc-sf-filter-load-more-loading-icon {
  margin: 0 auto;
  margin-left: -28px;
  margin-top: -28px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

#bc-sf-filter-error {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 100%;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  z-index: 999999999;
  transform: translate(-50%, -50%);
  font-size: 14px; }

#bc-sf-filter-error .btn-wrapper {
  text-align: right;
  margin-top: 10px; }

#bc-sf-filter-error .btn-wrapper button {
  border-radius: 3px;
  background: #fff;
  box-shadow: none;
  border: 1px solid #ccc;
  font-size: 12px; }

#bc-sf-filter-message {
  text-align: center;
  font-size: 14px; }

#bc-sf-filter-top-notification {
  text-align: center; }

.bc-sf-product-item-loading {
  height: auto;
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 40px; }

.bc-sf-product-item-loading:after, .bc-sf-product-item-loading:before {
  content: "";
  height: 10px;
  position: absolute;
  left: 0; }

.bc-sf-product-item-loading:before {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #e6e6e6), color-stop(33%, #eeeeee));
  width: 100%;
  bottom: 20px; }

.bc-sf-product-item-loading:after, .bc-sf-search-suggestion-loading > ul > li:before {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8; }

.bc-sf-product-item-loading:after {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #e6e6e6), color-stop(33%, #eeeeee));
  width: 60%;
  bottom: 0; }

.bc-sf-product-item-loading .bc-sf-product-item-loading-img {
  background-color: #f0f0f0;
  height: 150px;
  width: 100%;
  max-width: 100%; }

#bc-sf-filter-scroll-to-top {
  text-align: center;
  line-height: 45px;
  display: none;
  background-color: #000;
  z-index: 99;
  width: 45px;
  height: 45px;
  position: fixed;
  right: 30px;
  top: 85%;
  cursor: pointer;
  margin: 0;
  clear: both;
  overflow: hidden;
  font-size: 0; }

#bc-sf-filter-scroll-to-top span {
  display: block;
  color: #fff;
  background: 0 0 !important; }

#bc-sf-filter-scroll-to-top span:before {
  font-size: 16px;
  text-rendering: auto;
  content: "\e90e"; }

#bc-sf-filter-scroll-to-top.style2 {
  background-color: #f0f0f0;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

#bc-sf-filter-scroll-to-top.style2 span {
  color: #000; }

#bc-sf-filter-scroll-to-top.style2:hover {
  background-color: #222; }

#bc-sf-filter-scroll-to-top.style2:hover span:before {
  color: #fff; }

#bc-sf-filter-bottom-pagination {
  clear: both; }

#bc-sf-filter-load-more {
  text-align: center;
  clear: both;
  padding: 20px 0 30px; }

#bc-sf-filter-load-more .bc-sf-filter-load-more-button {
  color: #333;
  border: 1px solid #333;
  background-color: transparent;
  padding: 10px 20px;
  height: auto;
  vertical-align: top;
  line-height: 22px;
  text-transform: uppercase; }

#bc-sf-filter-load-more #bc-sf-filter-load-more-loading #bc-sf-filter-load-more-icon {
  background: url(bc-sf-filter-loading.gif) center 14px no-repeat white;
  margin: 0 auto;
  width: 56px;
  height: 56px;
  overflow: hidden;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  text-indent: -999em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  z-index: 99; }

.bc-sf-search-suggestion, .bc-sf-search-suggestion-wrapper {
  z-index: 99990;
  position: absolute; }

#bc-sf-filter-btn-load-previous-page {
  text-align: center;
  clear: both;
  margin-bottom: 20px; }

#bc-sf-filter-btn-load-previous-page .js-bc-sf-filter-btn-load-previous-page {
  height: auto;
  background-color: transparent;
  padding: 10px 20px;
  border: 1px solid #333;
  color: #000;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top; }

#bc-sf-filter-tree.bc-sf-filter-tree-no-filter-data, #bc-sf-filter-tree2.bc-sf-filter-tree-no-filter-data {
  display: none !important; }

.bc-sf-filter-products-no-filter-data {
  width: 100% !important; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider.bc-sf-filter-option-advanced-range-slider {
  margin-top: 8px !important; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider.bc-sf-filter-option-advanced-range-slider .noUi-value {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider.bc-sf-filter-option-advanced-range-slider .noUi-value:last-child {
  display: none; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-block-title {
  padding-bottom: 5px; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-block-content ul li h3 {
  font-size: inherit;
  margin-bottom: 0;
  margin-left: 20px; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-block-content ul li h3 a {
  font-size: inherit;
  font-weight: 400;
  text-transform: uppercase; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-block-content ul li h3 + ul {
  padding-bottom: 7px;
  padding-top: 10px;
  list-style: none;
  margin-left: 40px; }

#bc-sf-filter-wrapper {
  float: left;
  width: 100%; }

#bc-sf-filter-wrapper + * {
  clear: both; }

.bc-sf-filter-clear, .bc-sf-filter-clear-all {
  float: right;
  font-size: 80%;
  margin: 0;
  line-height: 22px; }

.bc-sf-filter-option-amount {
  margin-left: 5px; }

.bc-sf-filter-option-block {
  margin: 0;
  padding: 0 0 20px;
  border-bottom: 1px solid #e3e3e3; }

.bc-sf-filter-option-block:not(.bc-sf-filter-option-hidden) ~ .bc-sf-filter-option-block {
  padding-top: 20px; }

.bc-sf-filter-option-block:last-child {
  border-bottom: 0; }

.bc-sf-filter-option-block .bc-sf-filter-block-title {
  line-height: 20px;
  position: relative;
  overflow: visible;
  padding-bottom: 2px; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 20px; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 > span:before {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #000;
  display: inline-block;
  margin: 0 10px -2px 0; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 > span.up:before {
  border-top: none;
  border-bottom-color: #000;
  margin-bottom: 2px; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 a {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  float: right;
  letter-spacing: 0; }

.bc-sf-filter-option-block .bc-sf-filter-block-title.click h3 span:before {
  border-top-color: transparent;
  border-bottom-color: #000;
  margin-bottom: 2px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content {
  width: 100% !important;
  margin-top: 15px;
  overflow: hidden; }

.bc-sf-filter-option-block .bc-sf-filter-block-content a {
  cursor: pointer; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul {
  padding-left: 0;
  margin: 0; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li {
  position: relative;
  list-style: none;
  margin-bottom: 7px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li a {
  color: inherit;
  position: relative; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li ul {
  margin-left: 20px; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box {
  overflow: hidden; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li {
  padding: 0;
  margin-right: 2%;
  min-width: 22%;
  text-align: center; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a {
  vertical-align: middle;
  display: block;
  padding: 3px;
  width: auto; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a.selected {
  display: block;
  border-color: #000; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a.selected span {
  color: white !important; }

.bc-sf-filter-option-box .bc-sf-filter-option-amount {
  display: none; }

.bc-sf-filter-selection-wrapper {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-block-title {
  overflow: hidden;
  line-height: 20px; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-block-title h3 {
  font-size: 16px;
  margin: 0;
  text-transform: none;
  font-weight: 400;
  line-height: 20px; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-block-title .bc-sf-filter-clear {
  font-size: 11px;
  color: #2d2d2d;
  line-height: 21px; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items {
  margin-top: 15px; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item {
  text-transform: uppercase;
  color: #2d2d2d;
  clear: both; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item > a {
  color: inherit;
  display: block;
  text-decoration: none; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .selected-type span {
  font-weight: 400; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .selected-type strong, .bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .selected-type strong > span {
  font-weight: 700; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .bc-sf-filter-clear {
  width: 15px;
  height: 15px;
  float: right;
  margin-top: 2px;
  position: relative; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .bc-sf-filter-clear:before {
  content: '';
  display: block;
  width: 15px;
  height: 1px;
  background: #000;
  position: absolute;
  right: 0;
  top: 8px;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg); }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item .bc-sf-filter-clear:after {
  content: '';
  display: block;
  width: 15px;
  height: 1px;
  background: #000;
  position: absolute;
  right: 0;
  top: 8px;
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg); }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider.no-ruler .noUi-marker-normal, .bc-sf-filter-block-content .noUi-handle:after, .bc-sf-filter-block-content .noUi-handle:before {
  display: none; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item-label {
  margin: 10px 0;
  text-transform: uppercase; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item:before, .bc-sf-search-form .bc-sf-search-btn:before {
  font-family: bc-sf-filter-ico;
  text-rendering: auto;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bc-sf-filter-block-content .noUi-target {
  height: 6px;
  border-radius: 0;
  margin: 0;
  box-shadow: none; }

.bc-sf-filter-block-content .noUi-horizontal .noUi-origin .noUi-handle {
  width: 10px;
  height: 18px;
  left: 0;
  top: 0;
  box-shadow: none;
  border-radius: 3px;
  cursor: pointer; }

.bc-sf-filter-block-content .noUi-connect {
  background: #242424; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider {
  margin: 0 7px; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider.has-pips {
  margin-bottom: 35px; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-slider[disabled=disabled] {
  margin-bottom: 10px; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-amount {
  position: relative; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-amount:after {
  clear: both;
  content: '';
  display: block;
  margin-bottom: 10px; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-amount input {
  width: calc(50% - 15px);
  height: 30px;
  float: left;
  margin: 0 0 10px;
  padding: 1px 5px;
  line-height: 24px;
  border: 1px solid #e3e3e3;
  clear: none;
  min-height: auto;
  min-width: auto; }

.bc-sf-filter-block-content .bc-sf-filter-option-range-amount .bc-sf-filter-option-range-amount-split {
  float: left;
  font-size: 14px;
  line-height: 36px;
  width: 30px;
  text-align: center; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  color: #444;
  font-size: 11px;
  margin-top: 3px;
  margin-left: -4px;
  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal; }

.noUi-value-horizontal:last-child {
  margin-left: 0;
  left: auto !important;
  right: 0;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-pips-horizontal {
  left: 4px;
  height: 60px;
  width: 100%;
  padding: 0; }

.noUi-marker-horizontal.noUi-marker {
  width: 1px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 11px;
  margin-left: -4px;
  margin-top: -9px; }

.noUi-marker-horizontal.noUi-marker-large:first-child {
  margin-left: -5px; }

.noUi-origin {
  margin-left: -4px;
  top: -8px; }

.bc-sf-filter-range-value-lower {
  font-size: 11px;
  margin-top: 7px;
  margin-left: 4px;
  display: inline-block; }

.bc-sf-filter-range-value-upper {
  font-size: 11px;
  margin-top: 7px;
  float: right; }

.bc-sf-filter-option-show-search-box {
  padding: 10px;
  width: 100%;
  border: 1px solid #e3e3e3;
  height: 30px; }

@media screen and (min-width: 768px) {
  .bc-sf-filter-option-block[data-show-search-box-pc=true] .bc-sf-filter-option-show-search-box-wrapper {
    margin-top: 15px; }

  .bc-sf-filter-option-block[data-show-search-box-pc=false] .bc-sf-filter-option-show-search-box-wrapper {
    display: none !important; } }
@media screen and (max-width: 767px) {
  .bc-sf-filter-option-block[data-show-search-box-pc=true] .bc-sf-filter-option-show-search-box-wrapper {
    margin-top: 15px; }

  .bc-sf-filter-option-block[data-show-search-box-pc=false] .bc-sf-filter-option-show-search-box-wrapper {
    display: none !important; }

  .bc-sf-filter-tree-mobile-style2 .bc-sf-filter-option-block[data-show-search-box-mobile=true] .bc-sf-filter-option-show-search-box-wrapper {
    display: none; }

  .bc-sf-filter-tree-mobile-style2 .bc-sf-filter-option-block.bc-sf-filter-option-block-active[data-show-search-box-mobile=true] .bc-sf-filter-option-show-search-box-wrapper {
    display: block; }

  .bc-sf-filter-tree-mobile-style3 .bc-sf-filter-option-block[data-show-search-box-mobile=true] .bc-sf-filter-option-show-search-box-wrapper {
    display: none; } }
.bc-sf-filter-option-view-less {
  display: block;
  margin-top: 10px; }

@media screen and (min-width: 768px) {
  #bc-sf-filter-tree:not(.bc-sf-filter-tree-mobile-open) {
    display: block !important; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-multiple-list li a:hover {
    text-decoration: none;
    opacity: 1; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a:hover {
    text-decoration: none;
    border-color: #000;
    background: #000;
    color: #fff; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-box li a:hover span {
    color: #fff; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a.selected, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a:hover, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.square-grid li a.selected, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.square-grid li a:hover {
    font-weight: 400; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a.selected, .bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-swatch.circle-grid li a:hover {
    border-radius: 50%; }

  .bc-sf-search-suggestion {
    min-width: 300px; } }
.bc-sf-filter-option-block-sub_category .bc-sf-filter-option-single-list li span.sub-icon {
  cursor: pointer; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-option-single-list li span.sub-icon + a {
  display: inline-block !important; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-option-single-list li span.sub-icon.sub-up:before {
  border-top: none;
  border-bottom-color: #000;
  margin-bottom: 2px; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-option-single-list li span.sub-icon:before {
  content: '';
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #000;
  display: inline-block;
  margin: 0 10px -2px 0; }

.bc-sf-filter-option-block-sub_category .bc-sf-filter-option-single-list li span.sub-icon.hidden {
  display: none; }

#bc-sf-filter-top-show-limit label, #bc-sf-filter-top-sorting label {
  margin-right: 5px;
  display: inline-block; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item {
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  background: 0 0; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item:before {
  display: inline-block;
  font-size: 16px;
  color: #000; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item.active {
  cursor: default; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item.active:before, #bc-sf-filter-top-display-type .bc-sf-filter-display-item:hover:before {
  color: #ccc; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item.bc-sf-filter-display-grid:before {
  content: "\e908"; }

#bc-sf-filter-top-display-type .bc-sf-filter-display-item.bc-sf-filter-display-list:before {
  content: "\e907";
  font-size: 19px; }

.bc-sf-search-container {
  text-align: center;
  margin-bottom: 50px; }

.bc-sf-search-container .bc-sf-search-result-header {
  font-size: 20px; }

.bc-sf-search-form {
  position: relative;
  max-width: 60%;
  width: 100%;
  margin: 0 auto 10px;
  clear: both;
  padding-top: 0;
  padding-bottom: 0; }

.bc-sf-search-form .bc-sf-search-box {
  border: 1px solid #ececec;
  width: 100%;
  max-width: none;
  margin-bottom: 0;
  padding: 10px 40px 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  margin-top: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer; }

.bc-sf-search-form .bc-sf-search-btn {
  border: 0;
  width: 26px;
  height: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 0;
  vertical-align: middle;
  border-radius: 0;
  box-shadow: none;
  background: 0 0; }

.bc-sf-search-form .bc-sf-search-btn:before {
  display: inline-block;
  font-size: 16px;
  content: "\e902";
  color: #696969;
  font-weight: 600; }

.bc-sf-search-form .bc-sf-search-btn:hover:before {
  color: #000; }

.bc-sf-search-suggestion-mobile-top-panel *, .bc-sf-search-suggestion-wrapper * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  min-width: 0; }

.bc-sf-search-suggestion-header {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  background: #f5f5f5;
  text-align: left;
  padding: 5px 10px 4px;
  color: #a0a0a0;
  margin: 0;
  font-size: 12px; }

.bc-sf-search-suggestion {
  display: none;
  background: #fff;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 1px 5px 1px rgba(39, 44, 48, 0.16);
  border-radius: 2px; }

.bc-sf-search-suggestion li {
  list-style: none !important;
  clear: both; }

.bc-sf-search-suggestion > li:first-child > ul > li:first-child {
  -webkit-border-top-left-radius: 2px;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topleft: 2px;
  -moz-border-radius-topright: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.bc-sf-search-suggestion .bc-sf-search-suggestion-group li {
  float: left;
  width: 100%; }

.bc-sf-search-suggestion-header-popular + .bc-sf-search-suggestion-item a {
  color: inherit; }

.bc-sf-search-suggestion-header-view-all {
  text-align: center;
  background: 0 0;
  padding-bottom: 7px;
  padding-top: 7px;
  -webkit-border-bottom-right-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  clear: both; }

.bc-sf-search-suggestion-header-view-all > a {
  display: block;
  font-weight: 700;
  color: inherit !important;
  margin: 0 !important;
  border: none !important;
  background: 0 0 !important; }

.bc-sf-search-suggestion-header + .bc-sf-search-suggestion-item {
  padding-top: 5px; }

.bc-sf-search-suggestion-item {
  padding: 0 10px;
  margin: 0; }

.bc-sf-search-suggestion-item:last-child {
  padding-bottom: 5px; }

.bc-sf-search-suggestion-item > a {
  display: block;
  text-decoration: none;
  line-height: 1.375;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  background: 0 0 !important;
  overflow: visible;
  float: left;
  width: 100%; }

.bc-sf-search-suggestion-item a > b {
  font-weight: 400; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym {
  padding: 20px;
  margin: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  position: relative; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym:hover {
  background: 0 0;
  box-shadow: none; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym:before {
  content: "";
  background: #f5f5f5;
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym > * {
  position: relative; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym p {
  padding: 0;
  margin: 0;
  line-height: 22px; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym p:last-child {
  margin: 0; }

.bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym a, .bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym span {
  display: inline-block; }

.bc-sf-search-suggestion-item-product {
  overflow: visible;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  float: left;
  width: 100%; }

.bc-sf-search-suggestion-item-product .bc-sf-search-suggestion-left {
  float: left;
  display: inline-block;
  margin-right: 10px;
  width: 70px; }

.bc-sf-search-suggestion-item-product .bc-sf-search-suggestion-left img {
  width: auto;
  max-height: 84px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block; }

.bc-sf-search-suggestion-item-product .bc-sf-search-suggestion-right {
  overflow: visible;
  vertical-align: middle;
  float: right;
  display: block;
  width: calc(100% - 80px); }

.bc-sf-search-suggestion-product-title {
  line-height: 1.1;
  font-size: 110%;
  font-weight: 500;
  color: inherit;
  padding-bottom: 3px;
  margin-top: -3px; }

.bc-sf-search-suggestion-product-title b {
  font-weight: 400; }

.bc-sf-search-suggestion-product-sku {
  color: #a6a6a6;
  font-size: 85%;
  padding-bottom: 3px; }

.bc-sf-search-suggestion-product-vendor {
  color: #666;
  padding-bottom: 2px; }

.bc-sf-search-suggestion-product-price {
  color: #030303;
  font-weight: 700;
  line-height: 1.1; }

.bc-sf-search-suggestion-product-price s {
  text-decoration: line-through;
  color: #adadad; }

.bc-sf-search-suggestion-product-price s:after {
  background-color: initial; }

.bc-sf-search-suggestion-popover {
  position: absolute;
  top: -20px;
  right: 10%;
  overflow: hidden;
  height: 20px;
  width: 20px;
  margin: 0;
  z-index: 99991; }

.bc-sf-search-suggestion-popover[data-direction=left] {
  position: absolute;
  top: -20px;
  left: 10%;
  right: auto;
  overflow: hidden;
  height: 20px;
  width: 20px;
  margin: 0;
  z-index: 99991; }

.bc-sf-search-suggestion-popover:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  height: 10px;
  width: 10px;
  margin-left: -5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 1px 5px 1px rgba(39, 44, 48, 0.16); }

.bc-sf-filter-view-all-result {
  text-align: center;
  background: #f7f7f7;
  border: 1px solid #eee;
  padding: 5px 0; }

.bc-sf-search-suggestion-no-result {
  padding: 20px !important;
  color: #000; }

.bc-sf-search-suggestion-group {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important; }

.bc-sf-search-suggestion-group ul {
  display: block !important;
  left: 0 !important;
  top: 0 !important;
  padding: 0;
  margin: 0;
  border: none !important;
  position: relative !important; }

.bc-sf-search-suggestion-group[aria-label=Suggestions] .bc-sf-search-suggestion-item a {
  color: inherit; }

.bc-sf-search-suggestion-group[aria-label=Suggestions] .bc-sf-search-suggestion-item b {
  font-weight: 700; }

.bc-sf-search-suggestion-dym + .bc-sf-search-suggestion-item-product, .bc-sf-search-suggestion-group[data-group=products] + .bc-sf-search-suggestion-group[data-group=products] {
  border-top: 1px solid #f5f5f5; }

@media screen and (min-width: 1200px) {
  .bc-sf-search-box:focus {
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 5px rgba(0, 0, 0, 0.1); }

  .bc-sf-search-suggestion {
    min-width: 400px !important; } }
@media screen and (min-width: 768px) {
  .bc-sf-search-suggestion-wrapper-style2-width-fullwidth {
    width: 100% !important;
    left: 0 !important; }

  .bc-sf-search-suggestion-wrapper-style2-width-fullwidth .bc-sf-search-suggestion, .bc-sf-search-suggestion-wrapper-style2-width-fullwidth .bc-sf-search-suggestion-loading {
    width: 100% !important; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion {
    padding: 10px 20px 0; }

  .bc-sf-search-suggestion-wrapper-style2:not(.bc-sf-search-suggestion-wrapper-style2-width-auto) .bc-sf-search-suggestion {
    top: auto !important;
    left: auto !important; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group {
    width: 25%;
    float: right;
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group[data-group=products] {
    float: left;
    margin-bottom: 50px !important; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group[data-group=products] > ul {
    margin-left: -20px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group[data-group=products] .bc-sf-search-suggestion-item {
    padding-left: 20px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group[data-group=products] .bc-sf-search-suggestion-header-product {
    margin-left: 20px;
    width: calc(100% - 20px); }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-group[data-group=products], .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header-view-all {
    padding-right: 30px !important;
    width: 75%; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header {
    background: 0 0;
    box-shadow: none;
    border-bottom: 1px solid #f7f7f7;
    padding-left: 0;
    padding-right: 0; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header + .bc-sf-search-suggestion-item:not(.bc-sf-search-suggestion-dym) {
    padding-top: 15px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header + .bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym {
    margin-top: 10px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header-view-all {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: left;
    border-bottom: none;
    border-top: none;
    padding: 0 0 0 20px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-header-view-all a {
    border-top: 1px solid #f7f7f7 !important;
    display: block;
    line-height: 50px;
    font-size: 12px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-item {
    float: left;
    clear: none;
    border-bottom: none;
    padding-left: 0;
    padding-right: 0; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-item:last-child {
    padding-bottom: 15px; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-item:hover {
    box-shadow: none;
    background: 0 0;
    opacity: .6;
    filter: alpha(opacity=60); }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-item.bc-sf-search-suggestion-dym:before {
    left: 20px;
    right: 0; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-dym + .bc-sf-search-suggestion-item-product {
    border-top: none; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-product-title {
    line-height: 1.25; }

  .bc-sf-search-suggestion-wrapper-style2 .bc-sf-search-suggestion-item-product {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + li + li + li, .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + li + li + li + li + li, .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li + li, .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-item-product + li + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-item-product {
    width: 33.33%; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-2 .bc-sf-search-suggestion-item-product {
    width: 50%; }

  .bc-sf-search-suggestion-wrapper-style2:not(.bc-sf-search-suggestion-wrapper-bc-sf-search-box-1) .bc-sf-search-suggestion {
    top: auto !important; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group {
    float: left;
    width: 33.3%;
    padding-left: 30px !important; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group[data-group=products] {
    padding-top: 20px !important;
    float: right;
    width: 66.6%;
    border-left: 1px solid whitesmoke !important; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group[data-group=products] + .bc-sf-search-suggestion-group {
    padding-top: 20px !important; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group[data-group=products] > ul {
    margin-left: 0; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group[data-group=products] .bc-sf-search-suggestion-header-product {
    display: none; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-group .bc-sf-search-suggestion-header {
    border: none; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-header-view-all {
    width: 66.6%;
    left: auto;
    right: 0;
    border-left: 1px solid #f5f5f5;
    border-top: 1px solid #f5f5f5; }

  .bc-sf-search-suggestion-reverse-product-block-true .bc-sf-search-suggestion-header-view-all a {
    color: #fd876c;
    border: none !important; } }
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-item-product {
    width: 50%; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + li + li + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + li + li + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li {
    clear: left; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li + li {
    clear: none; }

  .bc-sf-search-suggestion-wrapper-style2.bc-sf-search-suggestion-products-per-row-3 .bc-sf-search-suggestion-header-product + .bc-sf-search-suggestion-dym + li + li + li + li + li {
    clear: left; } }
@keyframes placeHolderShimmer {
  0% {
    background-position: -150px 0; }

  100% {
    background-position: 150px 0; } }

.bc-sf-search-suggestion-loading > ul {
  margin: 10px 10px 0;
  padding: 0; }

.bc-sf-search-suggestion-loading > ul > li {
  height: 45px;
  margin-bottom: 10px;
  position: relative; }

.bc-sf-search-suggestion-loading > ul > li:before {
  content: "";
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #e6e6e6), color-stop(33%, #eeeeee));
  width: calc(100% - 55px);
  height: 10px;
  position: absolute;
  top: 8px;
  left: 55px; }

.bc-sf-search-suggestion-loading > ul > li:after {
  content: "";
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #e6e6e6), color-stop(33%, #eeeeee));
  width: 50%;
  height: 10px;
  position: absolute;
  top: 28px;
  left: 55px; }

.bc-sf-search-suggestion-loading .bc-sf-search-suggestion-loading-img {
  background-color: #f0f0f0;
  height: 100%;
  width: 30%;
  max-width: 45px; }

html.bc-sf-filter-mobile-detect-ios body.bc-sf-search-suggestion-mobile-open {
  position: fixed; }

body.bc-sf-search-suggestion-mobile-open {
  overflow: hidden;
  height: 100%; }

.bc-sf-search-suggestion-mobile.bc-sf-search-suggestion-wrapper {
  position: fixed;
  left: 0 !important;
  top: 50px !important;
  width: 100% !important;
  height: calc(100% - 50px);
  background: #f5f5f5;
  list-style: none;
  z-index: 9999999998;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #eee; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-popover {
  display: none !important; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion {
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  box-shadow: none;
  background: #f7f7f7;
  border-radius: 0;
  border: none !important;
  overflow-y: scroll;
  position: static !important;
  -webkit-overflow-scrolling: touch; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion > li > ul > li {
  margin-bottom: -1px;
  border: 1px solid #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion .bc-sf-search-suggestion-loading > ul > li:first-child {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-group {
  background: 0 0;
  margin: 0 10px !important; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-group > ul > .bc-sf-search-suggestion-header {
  padding-top: 6px;
  padding-bottom: 6px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-header {
  box-shadow: none;
  padding: 6px 5px;
  border: none !important;
  text-align: center; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-header + .bc-sf-search-suggestion-item {
  padding-top: 6px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-header-view-all {
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 1px solid #eeeeee !important;
  border-top: none !important;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 10px 5px;
  margin-bottom: 30px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-item {
  padding: 6px 10px;
  background: #fff; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-item:last-child {
  padding-bottom: 6px;
  margin-bottom: 0;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym {
  margin: 0;
  padding-top: 20px !important;
  border-bottom: 0 !important;
  padding-bottom: 10px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym.bc-sf-search-suggestion-item:last-child {
  padding-bottom: 20px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym:not(:last-child):before {
  bottom: 0; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym p {
  background: #f5f5f5; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym p:first-child {
  padding-top: 6px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym p:last-child {
  padding-bottom: 6px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-dym + .bc-sf-search-suggestion-item-product {
  border-top: 0 !important; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-item-product {
  padding-top: 10px !important;
  padding-bottom: 8px !important; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-item-product:last-child {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-loading > ul > li {
  background: #fff;
  padding: 10px;
  height: 65px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-loading > ul > li:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-loading > ul > li:before {
  top: 18px;
  left: 65px;
  width: calc(100% - 75px); }

.bc-sf-search-suggestion-mobile .bc-sf-search-suggestion-loading > ul > li:after {
  top: 38px;
  left: 65px; }

.bc-sf-search-suggestion-mobile-top-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9999999998;
  height: 50px;
  padding-bottom: 1px;
  display: none;
  background: rgba(0, 0, 0, 0.5); }

.bc-sf-search-suggestion-mobile-top-panel form {
  height: 50px;
  background: #fff;
  padding-top: 0;
  margin: 0; }

.bc-sf-search-suggestion-mobile-top-panel:after {
  display: none; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-btn-close-suggestion {
  background: #fff;
  width: 50px;
  height: 50px;
  float: left;
  border: none;
  font-size: 0;
  position: relative;
  border-right: 1px solid #f2f2f2;
  box-shadow: none; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-btn-close-suggestion:before {
  content: "\e912";
  position: absolute;
  width: 100%;
  font-family: bc-sf-filter-ico;
  font-size: 22px;
  top: 0;
  left: 0;
  line-height: 50px;
  color: #666; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile {
  width: 50px;
  height: 50px;
  float: right;
  font-size: 0;
  position: relative;
  border: none;
  background: #fff;
  display: block; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile span {
  display: block; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile span:before {
  content: "\e904";
  position: absolute;
  width: 100%;
  font-family: bc-sf-filter-ico;
  font-size: 18px;
  top: 0;
  left: 0;
  line-height: 52px;
  color: #fff;
  text-align: center;
  border-left: none;
  margin-top: -1px;
  z-index: 2; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile span:after {
  content: "\e917";
  position: absolute;
  width: 100%;
  font-family: bc-sf-filter-ico;
  font-size: 60px;
  top: 0;
  left: 0;
  line-height: 51px;
  text-align: center;
  border-left: none;
  margin-top: -1px;
  z-index: 1;
  overflow: hidden; }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile + #bc-sf-search-box-mobile {
  width: calc(100% - 150px); }

.bc-sf-search-suggestion-mobile-top-panel .bc-sf-search-submit-mobile + #bc-sf-search-box-mobile + .bc-sf-search-btn-clear-suggestion:before {
  font-size: 11px;
  line-height: 50px; }

.bc-sf-search-suggestion-mobile-top-panel #bc-sf-search-box-mobile {
  width: calc(100% - 100px);
  height: 50px;
  padding: 5px 15px;
  margin: 0;
  background: #fff;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  border-radius: none;
  text-align: left;
  font-size: 18px; }

.bc-sf-search-btn-clear-suggestion {
  background: #fff;
  width: 50px;
  height: 50px;
  float: right;
  font-size: 0;
  position: relative;
  box-shadow: none;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.bc-sf-search-btn-clear-suggestion:before {
  content: "\e914";
  position: absolute;
  width: 100%;
  height: 50px;
  font-family: bc-sf-filter-ico;
  font-size: 11px;
  top: 0;
  left: 0;
  line-height: 51px;
  color: #b9b9b9; }

.ui-helper-hidden-accessible {
  display: none !important; }

.bc-sf-search-suggestion-item.selected, .bc-sf-search-suggestion-item:hover {
  background: #f7f7f7;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03);
  position: relative;
  z-index: 2; }

.bc-sf-search-suggestion-item.selected a:hover, .bc-sf-search-suggestion-item:hover a:hover {
  text-decoration: none;
  border: none; }

@media screen and (min-width: 768px) {
  .bc-sf-search-suggestion-header-view-all:hover {
    background: #f5f5f5; }

  .bc-sf-search-suggestion-mobile, .bc-sf-search-suggestion-mobile-top-panel {
    display: none !important; } }
.bc-sf-search-suggestion-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 99999999;
  height: 100%;
  padding-bottom: 1px;
  display: none;
  background: rgba(0, 0, 0, 0.5); }

.bc-sf-filter-option-tooltip {
  position: relative;
  z-index: 99; }

.bc-sf-filter-option-tooltip:before {
  content: '?';
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  font-size: 11px;
  margin-left: 5px; }

.bc-sf-filter-option-tooltip .bc-sf-filter-tooltip-arrow {
  display: none;
  width: 7px;
  height: 7px;
  background: #fff;
  border: 1px solid #E2E2E2;
  border-top: none;
  border-left: none;
  position: absolute;
  top: -12px;
  left: 50%;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: -3px;
  z-index: 2; }

.bc-sf-filter-tooltip-open {
  background-color: #fff;
  border-color: #E2E2E2;
  color: #454545;
  font-size: 13px;
  padding: 5px;
  line-height: 20px; }

.bc-sf-filter-option-block .bc-sf-filter-block-title .bc-sf-filter-option-tooltip + .bc-sf-filter-tooltip-wrapper {
  width: auto;
  position: absolute;
  bottom: 30px;
  left: 0;
  text-align: center;
  display: none;
  max-width: 280px;
  min-width: 0;
  z-index: 99999; }

.bc-sf-filter-option-block .bc-sf-filter-block-title .bc-sf-filter-tooltip-wrapper .bc-sf-filter-qtip-content {
  word-wrap: break-word;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #e2e2e2 !important;
  color: #454545;
  font-size: 13px;
  padding: 10px 15px;
  line-height: 20px;
  text-transform: initial;
  max-width: 100%;
  min-width: 50px;
  font-weight: 400;
  text-align: left; }

.bc-sf-filter-option-tooltip:hover .bc-sf-filter-tooltip-arrow, .bc-sf-filter-option-tooltip:hover .bc-sf-filter-tooltip-wrapper, .bc-sf-filter-option-tooltip:hover + .bc-sf-filter-tooltip-wrapper, .bc-sf-filter-option-tooltip:hover + a + .bc-sf-filter-tooltip-wrapper, .bc-sf-filter-option-tooltip:hover:after {
  display: block !important; }

#bc-sf-filter-tree-h .bc-sf-filter-option-block .bc-sf-filter-block-title a .bc-sf-filter-option-tooltip + .bc-sf-filter-tooltip-wrapper {
  width: 280px;
  left: 0;
  margin-left: 0;
  bottom: 33px;
  text-align: left;
  height: auto;
  border: none; }

#bc-sf-filter-tree-h .bc-sf-filter-option-block .bc-sf-filter-block-title a .bc-sf-filter-option-tooltip + .bc-sf-filter-tooltip-wrapper .bc-sf-filter-qtip-content {
  position: static;
  height: auto; }

#bc-sf-filter-tree-h .bc-sf-filter-option-tooltip .bc-sf-filter-tooltip-arrow {
  top: -12px; }

@media screen and (max-width: 767px) {
  #bc-sf-filter-tree {
    display: none;
    width: 100% !important;
    float: none !important; }

  #bc-sf-filter-tree-h {
    display: none; }

  #bc-sf-filter-tree-mobile {
    display: block; }

  .bc-sf-filter-option-block .bc-sf-filter-block-content ul li a {
    opacity: 1 !important; }

  #bc-sf-filter-top-show-limit, #bc-sf-filter-top-sorting {
    width: auto;
    margin-top: 0; }

  #bc-sf-filter-scroll-to-top {
    width: 40px;
    height: 40px;
    opacity: .7;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -ms-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    background-position: 14px 17px !important;
    top: inherit;
    right: 15px;
    bottom: 50px;
    overflow: hidden;
    vertical-align: baseline;
    padding: 0; }

  #bc-sf-filter-top-display-type .bc-sf-filter-display-item:nth-of-type(1) {
    margin-left: 0; }

  .bc-sf-search-form {
    max-width: 100%; }

  .bc-sf-filter-option-swatch.square-list li a {
    display: table !important;
    width: 100%; }

  .bc-sf-filter-option-swatch.square-list li a span {
    display: table-cell !important;
    vertical-align: middle; }

  .bc-sf-filter-option-swatch.square-list li a span.bc-sf-filter-option-value {
    padding-left: 5px; }

  .bc-sf-filter-option-swatch.square-list li a span.bc-sf-filter-option-amount {
    float: none;
    text-align: right; }

  .bc-sf-filter-tree-mobile-full-width.bc-sf-filter-tree-mobile-open {
    position: fixed;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #fff;
    list-style: none;
    z-index: 9999999998;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: left; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree #bc-sf-filter-options-wrapper {
    height: 75%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block {
    white-space: nowrap;
    margin: 0;
    padding: 15px 20px !important;
    text-align: center;
    position: relative; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-swatch.circle-grid, .bc-sf-filter-tree-mobile-style2 .bc-sf-filter-option-swatch.square-grid {
    white-space: normal; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title {
    padding: 0 8px;
    position: relative; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title:after {
    content: "";
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: translate(0, -50%) rotate(-45deg);
    -webkit-transform: translate(0, -50%) rotate(-45deg);
    position: absolute;
    top: 50%;
    right: 0; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title h3 span:before, .bc-sf-filter-tree-mobile-style3#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title:after {
    content: none; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title.left:after, .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title.up:after {
    transform: translate(0, -50%) rotate(-135deg);
    -webkit-transform: translate(0, -50%) rotate(-135deg); }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title.down:after {
    transform: translate(0, -50%) rotate(45deg);
    -webkit-transform: translate(0, -50%) rotate(45deg); }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title .bc-sf-filter-clear {
    display: none; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title p {
    white-space: normal;
    font-size: 14px;
    margin: 0; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-content {
    display: none;
    text-align: left;
    padding: 0 !important;
    max-height: max-content; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-content ul li a {
    display: block;
    margin-bottom: 7px;
    overflow: hidden; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block:not(.bc-sf-filter-option-block-active) .bc-sf-filter-option-view-less, .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-block:not(.bc-sf-filter-option-block-active) .bc-sf-filter-option-view-more {
    display: none !important; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-amount {
    float: right; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-swatch.circle-grid li a span.bc-sf-filter-option-value {
    padding-left: 5px; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-swatch.circle-grid li a span.bc-sf-filter-option-amount {
    float: none;
    text-align: right; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-swatch.square-list li a {
    display: table !important;
    width: 100%; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-option-swatch.square-list li a span {
    display: table-cell !important;
    vertical-align: middle; }

  .bc-sf-filter-tree-mobile-full-width#bc-sf-filter-tree .bc-sf-filter-selection-wrapper {
    padding: 15px 28px;
    display: none; }

  .bc-sf-filter-tree-mobile-style2#bc-sf-filter-tree .bc-sf-filter-option-block-active {
    max-height: 100%;
    border: none;
    padding-top: 0 !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 15px !important; }

  .bc-sf-filter-mobile-toolbar-header, .bc-sf-filter-mobile-toolbar-items {
    border-bottom: 1px solid #e3e3e3;
    line-height: 14px;
    text-transform: uppercase; }

  .bc-sf-filter-tree-mobile-style3#bc-sf-filter-tree .bc-sf-filter-option-block .bc-sf-filter-block-title p {
    margin: 5px 0; }

  .bc-sf-filter-tree-mobile-style3#bc-sf-filter-tree .bc-sf-filter-block-content {
    max-height: 220px !important;
    overflow-y: hidden; }

  .bc-sf-filter-mobile-toolbar-header {
    background: #f3f3f3;
    padding: 15px 0;
    text-align: center; }

  .bc-sf-filter-mobile-toolbar-items {
    background: #fff;
    padding: 15px; }

  .bc-sf-filter-mobile-toolbar-items a {
    color: #333;
    position: relative;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    padding: 0; }

  .bc-sf-filter-mobile-toolbar-items .bc-sf-filter-close-btn {
    color: #949494; }

  .bc-sf-filter-mobile-toolbar-items .bc-sf-filter-mobile-toolbar-left {
    display: inline-block; }

  .bc-sf-filter-mobile-toolbar-items .bc-sf-filter-mobile-toolbar-left a {
    padding-left: 15px; }

  .bc-sf-filter-mobile-toolbar-items .bc-sf-filter-mobile-toolbar-left a:before {
    content: "";
    border: solid #000;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: absolute;
    left: 0;
    top: 4px; }

  .bc-sf-filter-mobile-toolbar-items .bc-sf-filter-mobile-toolbar-right {
    float: right; }

  #bc-sf-filter-mobile-footer {
    position: relative;
    bottom: 0;
    width: 100%; }

  #bc-sf-filter-mobile-footer button {
    padding: 15px;
    background: #333;
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px; }

  .bc-sf-filter-option-swatch.circle-grid li a .bc-sf-filter-option-swatch-image, .bc-sf-filter-option-swatch.circle-list li a .bc-sf-filter-option-swatch-image {
    background-clip: padding-box; } }
.bc-sf-filter-skeleton-text {
  height: 8px;
  max-width: 100%;
  min-width: 50px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 50em;
  display: inline-block;
  -webkit-animation: bc-sf-filter-skeleton-animation 2s infinite;
  animation: bc-sf-filter-skeleton-animation 2s infinite; }

.bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width1 {
  width: 50px; }

.bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width2 {
  width: 100px; }

.bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width3 {
  width: 150px; }

.bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width4 {
  width: 200px; }

.bc-sf-filter-skeleton-button {
  background-color: rgba(0, 0, 0, 0.07) !important;
  -webkit-animation: bc-sf-filter-skeleton-animation 2s infinite;
  animation: bc-sf-filter-skeleton-animation 2s infinite; }

.bc-sf-filter-skeleton-button > span {
  background-color: rgba(0, 0, 0, 0.1);
  height: 8px;
  border-radius: 50em;
  width: 30%;
  display: inline-block; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-skeleton-text {
  max-width: 100%;
  display: block; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width1 {
  width: 40%; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width2 {
  width: 65%; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width3 {
  width: 80%; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-skeleton-text.bc-sf-filter-skeleton-width4 {
  width: 100%; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-title > a, .bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-title > h3 {
  display: block; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-title > a span:after, .bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-title > h3 span:after {
  content: '';
  height: 12px;
  width: 28%;
  background: #ececec;
  border-radius: 50em;
  display: inline-block;
  -webkit-animation: bc-sf-filter-skeleton-animation 2s infinite;
  animation: bc-sf-filter-skeleton-animation 2s infinite; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-content .bc-sf-filter-skeleton-text:not(:last-child) {
  margin-bottom: 18px; }

.bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-content .bc-sf-filter-skeleton-text:not(:first-child) {
  margin-top: 10px; }

#bc-sf-filter-tree-h .bc-sf-filter-option-block.bc-sf-filter-option-skeleton .bc-sf-filter-block-title > a span:after {
  width: 80px;
  height: 10px; }

.bc-sf-filter-product-skeleton .bc-sf-filter-skeleton-image {
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 5%;
  -webkit-animation: bc-sf-filter-skeleton-animation 2s infinite;
  animation: bc-sf-filter-skeleton-animation 2s infinite; }

.bc-sf-filter-product-skeleton .bc-sf-filter-skeleton-meta > span {
  display: block;
  margin-bottom: 15px; }

@-webkit-keyframes bc-sf-filter-skeleton-animation {
  0%,100% {
    opacity: 1; }

  50% {
    opacity: .5; } }

@keyframes bc-sf-filter-skeleton-animation {
  0%,100% {
    opacity: 1; }

  50% {
    opacity: .5; } }

#bc-sf-filter-load-more-total {
  text-align: center;
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .15em; }

/**************************** Place your custom css below  ****************************/
#bc-sf-filter-tree {
  width: 20%;
  float: left; }

#bc-sf-filter-right {
  width: 80%;
  float: right; }

#bc-sf-filter-right #bc-sf-filter-products {
  width: 100%;
  float: right;
  overflow: visible; }

@media screen and (max-width: 767px) {
  #bc-sf-filter-tree {
    display: none;
    width: 100%;
    float: none; }

  #bc-sf-filter-right, #bc-sf-filter-right #bc-sf-filter-products {
    width: auto;
    float: none; }

  #bc-sf-filter-tree-mobile button {
    margin-top: -20px; }

  #bc-sf-filter-products {
    padding-top: 20px; } }
#bc-sf-filter-wrapper {
  overflow: visible; }

.bc-sf-filter-clear, .bc-sf-filter-clear-all {
  font-size: .9em;
  color: #555;
  margin-top: 2px; }

.bc-sf-filter-clear:hover, .bc-sf-filter-clear-all:hover {
  color: #555; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 {
  font-size: .9em; }

.bc-sf-filter-option-block .bc-sf-filter-block-title h3 span {
  cursor: pointer;
  font-size: .9em; }

.bc-sf-filter-option-block .bc-sf-filter-block-content {
  color: #555;
  font-size: .9em; }

.bc-sf-filter-option-block .bc-sf-filter-block-content ul li a {
  color: #555;
  font-size: 0.9em; }

.bc-sf-filter-option-block .bc-sf-filter-block-content label {
  float: inherit;
  font-size: .9em; }

.bc-sf-filter-option-block .bc-sf-filter-block-content .bc-sf-filter-option-range-amount {
  font-size: .9em; }

.bc-sf-filter-selection-wrapper .bc-sf-filter-selected-items .selected-item {
  color: #555;
  font-size: 0.9em; }

.bc-al-exist {
  overflow: initial !important; }

.bc-sf-widget-products .owl-stage-outer {
  padding-top: 20px; }

#bc-sf-filter-top-sorting {
  width: inherit !important; }

.bc-sf-filter-sku {
  font-family: 'Roboto Medium';
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 0; }

#bc-sf-filter-products .grid-link__title {
  font-family: 'Roboto Condensed';
  max-height: 5.4em;
  overflow: hidden; }
#bc-sf-filter-products .grid-link__meta {
  text-align: center;
  font-family: 'Roboto Condensed';
  font-size: 1.2em;
  font-weight: bold; }

.bc-addtocart-btn {
  font-family: 'Roboto Condensed';
  text-transform: uppercase; }

.bc-sf-search-suggestion-header {
  background-color: #051c45;
  color: white;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase; }
  .bc-sf-search-suggestion-header:hover {
    background-color: #b4ccdc; }

.bc-sf-search-suggestion-product-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 0.9em; }

.bc-sf-search-suggestion-product-sku {
  font-family: 'Roboto Condensed', sans-serif;
  color: #0f3e69;
  font-weight: bold; }

.bc-sf-search-suggestion-product-vendor {
  font-family: 'Roboto Condensed', sans-serif; }
